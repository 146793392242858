import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="mr-64 ml-64 mx-auto">
        <div className=" overflow-hidden rounded">
          <div className="px-6 px-4 text-center my-32">
            <div className="text-6xl text-black">
              <p>Not Found.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
